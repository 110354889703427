
  import { Component, Vue, Watch } from "vue-property-decorator";
  import { formatDate, formatTime } from "@/date-utils";
  import { IChangeRequest, IChangeRequestsWrapper } from "@/interfaces";
  import { api } from "@/api";
  import { readToken } from "@/store/main/getters";
  import { dispatchAddNotification } from "@/store/main/actions";
  import { debounce } from "@/utils";

  const debounceSearchChangeRequests = debounce(async (component, token, params) => {
    const resp = await api.searchChangeRequests(token, params);
    component.changeRequestsResults = resp.data;
    component.loading = false;
  }, 300);

  @Component
  export default class AdminChangeRequests extends Vue {
    public headers = [
      {
        text: "Approve/Deny",
        sortable: false,
        value: "approve_deny",
        align: "left",
      },
      {
        text: "Full Name",
        sortable: true,
        value: "full_name",
        align: "left",
      },
      {
        text: "Requested Change",
        sortable: true,
        value: "new_data",
        align: "left",
      },
      {
        text: "Status",
        sortable: true,
        value: "status",
        align: "left",
      },
      {
        text: "Timestamp",
        sortable: true,
        value: "creation_date",
        align: "left",
      },
    ];

    loading = false;
    options = {
      sortBy: ["creation_date"],
      sortDesc: ["true"],
      page: 1,
      itemsPerPage: 50,
    };
    searchQuery: string | null = null;
    changeRequestsResults: IChangeRequestsWrapper = {
      pagination: { total: 0 },
      change_requests: [],
    };
    showOld = false;
    selected: IChangeRequest[] = [];

    get changeRequests() {
      return this.changeRequestsResults.change_requests;
    }

    get totalChangeRequests() {
      return this.changeRequestsResults.pagination.total;
    }

    async refreshData() {
      this.loading = true;
      const token = readToken(this.$store);

      const params = {
        limit: this.options.itemsPerPage,
        skip: this.options.itemsPerPage * (this.options.page - 1),
      };
      if (this.options.sortBy.length > 0) {
        params["sort_by"] = this.options.sortBy[0];
      }
      if (this.options.sortDesc.length > 0) {
        params["desc"] = !!this.options.sortDesc[0];
      }
      if (this.searchQuery) {
        params["query"] = this.searchQuery;
      }
      params["show_old"] = this.showOld;

      await debounceSearchChangeRequests(this, token, params);
    }

    @Watch("options")
    async onOptionsChange() {
      this.refreshData();
    }

    @Watch("searchQuery")
    async onSearchQueryChange() {
      this.options.page = 1;
      this.refreshData();
    }

    @Watch("showOld")
    async onShowOldChange() {
      this.refreshData();
    }

    formatDate(date: string) {
      return formatDate(date, null);
    }

    formatTime(date: string) {
      return `${formatTime(date, null)}`;
    }

    public async mounted() {
      await this.refreshData();
    }

    formatNewData(newData: string) {
      return newData.replaceAll(/[{}"]/g, "");
    }

    async respond(id: number, approve: boolean) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const token = readToken(this.$store);
      if (approve) {
        await api.approveChangeRequest(token, id);
      } else {
        await api.denyChangeRequest(token, id);
      }
      dispatchAddNotification(this.$store, {
        content: approve ? "Request Approved" : "Request Denied",
      });
      await this.refreshData();
      this.loading = false;
    }

    async respondBulk(approve: boolean) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const token = readToken(this.$store);
      for (let i = 0; i < this.selected.length; i++) {
        const id = this.selected[i].id;
        if (approve) {
          await api.approveChangeRequest(token, id);
        } else {
          await api.denyChangeRequest(token, id);
        }
      }
      dispatchAddNotification(this.$store, {
        content: approve ? "Requests Approved" : "Requests Denied",
      });
      await this.refreshData();
      this.loading = false;
    }
  }
